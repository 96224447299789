import React from 'react';
import './Secondpage.css';
import flechas from '../../img/flechas.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Secondpage() {
    const [t] = useTranslation('global');

    return (
        <div className='secondpage' id='secondpage'>
            <div className='title-btn'>
                <div className="title2">
                    <p>{t('secondpage.twoStrat')}</p>
                    <p className='take-yourE'>{t('secondpage.take')}</p>
                </div>
                <div className="btn">
                <Link to='/calendly'><button id='btn1-call-2nd' className='btn-call'>{t('homepage.btn-call')}</button></Link>
                </div>
            </div>
            
            <div className="info2nd">
                <div className="paid-ads">
                    <p>{t('secondpage.paidAds')}</p>
                    <img src={flechas} alt='flechas' className='flechas' id='flechas1'/>
                    <p className='detail2nd'>{t('secondpage.textPaidAds')}</p>
                </div>
                <div className="influencers">
                    <div>
                    <p className='inf-title'>{t('secondpage.influencers')}</p>
                    </div>
                    <img src={flechas} alt='flechas' className='flechas'/>
                    <p className='detail2nd'>{t('secondpage.textInfluencers')}</p>
                </div>
            </div>

            <div className="btn2">
            <Link to='/calendly'><button id='btn2-call-2nd' className='btn-call'>{t('homepage.btn-call')}</button></Link>
            </div>
        </div>
    );
}

export default Secondpage;