import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
    function scrollHomepage() {
        const homepage = document.getElementById('homepage');
        homepage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollSecondpage() {
        const secondpage = document.getElementById('secondpage');
        secondpage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollThirdpage() {
        const thirdpage = document.getElementById('thirdpage');
        thirdpage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollFourthpage() {
        const fourthpage = document.getElementById('fourthpage');
        fourthpage.scrollIntoView({ behavior: 'smooth' });
    }

    const [t] = useTranslation('global');

    return (
        <footer>
                <div className="waves">
                    <div className="wave" id='wave1'></div>
                    <div className="wave" id='wave2'></div>
                    <div className="wave" id='wave3'></div>
                    <div className="wave" id='wave4'></div>
                </div>
                <ul className='icons'>
                    <li><Link to='https://www.facebook.com/profile.php?id=100090933894046' target='_blank'><FontAwesomeIcon icon={faFacebook} /></Link></li>
                    <li><Link to='https://www.instagram.com/moonlight__ads/' target='_blank'><FontAwesomeIcon icon={faInstagram} /></Link></li>
                    <li><Link to='https://linkedin.com/company/moonlight-ads' target='_blank'><FontAwesomeIcon icon={faLinkedin} /></Link></li>
                </ul>
                <ul className="menu">
                    <li><Link onClick={scrollHomepage}>{t('navbar.home')}</Link></li>
                    <li><Link onClick={scrollSecondpage}>{t('navbar.services')}</Link></li>
                    <li><Link onClick={scrollThirdpage}>{t('navbar.aboutUs')}</Link></li>
                    <li><Link onClick={scrollFourthpage}>{t('navbar.work')}</Link></li>
                    <li className='contactUs'><Link to='/calendly' target='_blank'>{t('navbar.call')}</Link></li>
                </ul>
                <p className='rights'>{t('footer.rights')}</p>
        </footer>
    );
}

export default Footer;