import React from 'react';
import './Fourthpage.css';
import cuadro1 from '../../img/cuadro1.png';
import cuadro2 from '../../img/cuadro2.png';
import cuadro3 from '../../img/cuadro3.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Fourthpage() {
    const [t] = useTranslation('global');

    return (
        <div className='fourthpage' id='fourthpage'>
            <div className="fourth-title">
                <p>{t('fourthpage.title')}</p>
            </div>
            <div className="info4th">
                <div className="campaign">
                    <img src={cuadro1} alt='cuadro1' className='cuadro1'/>
                    <div className="content4th">
                        <p>{t('fourthpage.campaign')}</p>
                        <p className='detail4th' id='detail4th-p'>{t('fourthpage.textCampaign')}</p>
                    </div>
                </div>

                <div className="campaign">
                    <img src={cuadro2} alt='cuadro2' className='cuadro1'/>
                    <div className="content4th">
                        <p>{t('fourthpage.testing')}</p>
                        <p className='detail4th'>{t('fourthpage.textTesting1')}</p>
                        <p className='detail4th-last'>{t('fourthpage.textTesting2')}</p>
                    </div>
                </div>

                <div className="campaign">
                    <img src={cuadro3} alt='cuadro3' className='cuadro1'/>
                    <div className="content4th">
                        <p>{t('fourthpage.growing')}</p>
                        <p className='detail4th'>{t('fourthpage.textGrowing1')}</p>
                        <p className='detail4th-last'>{t('fourthpage.textGrowing2')}</p>
                    </div>
                </div>
            </div>

            <Link to='/calendly'><button id='btn-call-footer' className='btn-call'>{t('homepage.btn-call')}</button></Link>
        </div>
    );
}

export default Fourthpage;