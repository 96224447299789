import { Route, Routes } from 'react-router-dom';
import './App.css';
import Inicio from './components/Inicio/Inicio';
import Calendly from './components/Calendly/Calendly';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Inicio/>}/>
      <Route path='/calendly' element={<Calendly/>}/>
    </Routes>
);
}

export default App;