import React from 'react';
import './Thirdpage.css';
import { useTranslation } from 'react-i18next';

function Thirdpage() {
    const [t] = useTranslation('global');

    return (
        <div className='thirdpage' id='thirdpage'>
            <div className="third-title">
                <p>{t('thirdpage.title')}</p>
            </div>
            <div className="info3rd">
                <div className="transparency">
                    <p>{t('thirdpage.transparency')}</p>
                    <p className='detail3rd'>{t('thirdpage.textTransparency')}</p>
                </div>
                <hr/>
                <div className="results">
                    <p>{t('thirdpage.results')}</p>
                    <p className='detail3rd' id='detail3rd-p'>{t('thirdpage.textResults')}</p>
                </div>
                <hr/>
                <div className="innovation">
                    <p>{t('thirdpage.innovation')}</p>
                    <p className='detail3rd'>{t('thirdpage.textInnovation')}</p>
                </div>
            </div>
        </div>
    );
}

export default Thirdpage;