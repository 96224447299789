import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import './NavbarCalendly.css';
import arg from '../../img/arg.png';
import eeuu from '../../img/eeuu.png';
import { useTranslation } from 'react-i18next';

function NavbarCalendly() {
    const [t, i18n] = useTranslation('global');

    return (
        <div id='list-calendly' className="list">
            <div className='logo-nav'>
                <Link to='/'><img src={logo} alt="logo" className='logo'/></Link>
                <p className='logo-title'><Link to='/'>MoonLight</Link></p>
            </div>
            <div className="language">
                <img src={arg} alt="arg" className='arg' onClick={() => i18n.changeLanguage('es')}/>
                <img src={eeuu} alt="eeuu" onClick={() => i18n.changeLanguage('en')}/>
            </div>
        </div>
    );
}

export default NavbarCalendly;