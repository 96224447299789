import React from 'react';
import Footer from '../Footer/Footer';
import Fourthpage from '../Fourthpage/Fourthpage';
import Homepage from '../Homepage/Homepage';
import Navbar from '../Navbar/Navbar';
import Secondpage from '../Secondpage/Secondpage';
import Thirdpage from '../Thirdpage/Thirdpage';
import './Inicio.css';
import Partners from '../Partners/Partners';

function Inicio() {
    return (
        <>
            <div className="first" id='homepage'>
                <Navbar/>
                <Homepage/>
            </div>
            <div className='trusted-partners'>
                <Partners/>
            </div>
            <div className="second">
                <Secondpage/>
            </div>
            <div className="third">
                <Thirdpage/>
            </div>
            <div className="fourth">
                <Fourthpage/>
                <Footer/>
            </div>
        </>
    );
}

export default Inicio;