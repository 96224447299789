import React from 'react';
import './Homepage.css';
import olas from '../../img/olas.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Homepage() {
    const [t] = useTranslation('global');

    return (
        <div className='homepage'>
            <img src={olas} alt='Olas' className='olas'/>
            <div className="info1st">
                <>
                <p className='blow-up'>{t('homepage.blowUp')}</p>
                <p className='blow-up'>{t('homepage.yourProfits')}</p>
                </>
                <div className='div-maximize'>
                <p className='maximize1'>{t('homepage.maximize')}</p>
                <p className="maximize">{t('homepage.revenue')}</p>
                </div>
                <Link to='/calendly'><button id='btn-call-homepage' className='btn-call'>{t('homepage.btn-call')}</button></Link>
            </div>
        </div>
    );
}

export default Homepage;