import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo.png';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import arg from '../../img/arg.png';
import eeuu from '../../img/eeuu.png';

function Navbar() {
    const [icon, setIcon] = useState('bars');

    const toggleBtn = () => {
        const newIcon = icon === 'bars' ? 'xmark' : 'bars';
        setIcon(newIcon);

        const dropdownMenu = document.querySelector('.dropdown-menu');
        dropdownMenu.classList.toggle('open');
    }

    function scrollHomepage() {
        const homepage = document.getElementById('homepage');
        homepage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollSecondpage() {
        const secondpage = document.getElementById('secondpage');
        secondpage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollThirdpage() {
        const thirdpage = document.getElementById('thirdpage');
        thirdpage.scrollIntoView({ behavior: 'smooth' });
    }
    function scrollFourthpage() {
        const fourthpage = document.getElementById('fourthpage');
        fourthpage.scrollIntoView({ behavior: 'smooth' });
    }

    const [t, i18n] = useTranslation('global');

    return (
        <nav>
            <ul className='list'>
                <div className='logo-nav'>
                    <Link to='/'><img src={logo} alt="logo" className='logo'/></Link>
                    <li className='logo-title'><Link to='/'>MoonLight</Link></li>
                </div>
                
                <div className='items-nav'>
                    <li><Link onClick={scrollHomepage}>{t('navbar.home')}</Link></li>
                    <li><Link onClick={scrollSecondpage}>{t('navbar.services')}</Link></li>
                    <li><Link onClick={scrollThirdpage}>{t('navbar.aboutUs')}</Link></li>
                    <li><Link onClick={scrollFourthpage}>{t('navbar.work')}</Link></li>
                </div>
                <li id='calendly-call' className='contact-us'><Link to='/calendly'>{t('navbar.call')}</Link></li>
                <div className='flag-dropdownBtn'>
                    <div className="language">
                        <img src={arg} alt="arg" className='arg' onClick={() => i18n.changeLanguage('es')}/>
                        <img src={eeuu} alt="eeuu" onClick={() => i18n.changeLanguage('en')}/>
                    </div>
                    <div className="toggle-btn">
                        <button onClick={toggleBtn} className='toggle-btn'><FontAwesomeIcon icon={icon === 'bars' ? faBars : faXmark} style={{color: "#ffffff",}} /></button>
                    </div>
                </div>
                <div className="dropdown-menu">
                    <li><Link onClick={scrollHomepage}>{t('navbar.home')}</Link></li>
                    <li><Link onClick={scrollSecondpage}>{t('navbar.services')}</Link></li>
                    <li><Link onClick={scrollThirdpage}>{t('navbar.aboutUs')}</Link></li>
                    <li><Link onClick={scrollFourthpage}>{t('navbar.work')}</Link></li>
                    <li id='calendly-call' className='contact-us'><Link to='/calendly'>{t('navbar.call')}</Link></li>
                </div>
            </ul>
        </nav>
    );
}

export default Navbar;