import React from 'react';
import './Calendly.css';
import { InlineWidget } from "react-calendly";
import NavbarCalendly from '../NavbarCalendly/NavbarCalendly';
import FooterCalendly from '../FooterCalendly/FooterCalendly';
import { useTranslation } from 'react-i18next';

function Calendly() {
    const [t] = useTranslation('global');

    return (
        <div className='calendly'>
            <NavbarCalendly/>
            <div className="calendly-title">
                <p>{t('calendly.title')}</p>
            </div>
            <div className="calendly-items">
                <div className="infoCalendly">
                    <div className="title-calendly">
                        <p>{t('calendly.audit')}</p>
                    </div>
                    <div className="list-calendly">
                        <ul>
                            <li>{t('calendly.textAudit1')}<b>{t('calendly.textAudit1Online')}</b></li>
                            <li>{t('calendly.textAudit2')}<b>{t('calendly.textAudit2Maximizing')}</b>{t('calendly.textAudit2With')}</li>
                            <li>{t('calendly.textAudit3')}<b>{t('calendly.textAudit3Prioritize')}</b></li>
                        </ul>
                    </div>
                </div>
                <div className="infoCalendly">
                    <div className="title-calendly">
                        <p>{t('calendly.end')}</p>
                    </div>
                    <div className="info-calendly">
                        <p>{t('calendly.textEnd')}<b>{t('calendly.textEndAchieve')}</b>{t('calendly.textEnd2')}<b>{t('calendly.textEndPaid')}</b></p>
                    </div>
                </div>
            </div>
            <InlineWidget url={t('calendly.URL')} styles={{height: '700px', margin: '0 0 7rem 0'}}/>

            <FooterCalendly/>
        </div>
    );
}

export default Calendly;