import React from 'react';
import './Partners.css';
import goldenbird from '../../img/goldenbird.png';
import helpem from '../../img/helpem.png';
import N from '../../img/N.png';
import olimpsay from '../../img/Olimpsay 2021 logo.png';
import purplemango from '../../img/purplemango.png';
import blackfyre from '../../img/blackfyre.png';

function Partners() {
    return (
        <div className='partners'>
            <p className='title-partners'>Trusted by 10+ partners</p>
            <div className="container-partners">
                <img src={purplemango} alt="purplemango" className='logo-purplemango'/>
                <img src={olimpsay} alt="olimpsay" className='logo-olimpsay'/>
                <img src={N} alt="N" className='logo-N'/>
                <img src={helpem} alt="helpem" className='logo-helpem'/>
                <img src={goldenbird} alt="goldenbird" className='logo-goldenbird'/>
                <img src={blackfyre} alt="goldenbird" className='logo-blackfyre'/>
            </div>
        </div>
    );
}

export default Partners;